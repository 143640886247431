import React, { FC, forwardRef, useState, useEffect, createContext, useContext ,useRef} from "react";
import { WithChildren } from "../helpers/AppHelpers";
import { Icon } from "react-icons-kit";
import { search } from "react-icons-kit/fa/search";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../modules/auth/core/Auth";

import '../../../src/assets/css/dashboard.css'


const HeaderContext = createContext<any>({
    pageTitle: "Page Title",
    searchQuery: "",
});
const UpdateHeaderContext = createContext<any>(null);

const useHeaderContext = () => useContext(HeaderContext);
const useUpdateHeaderContext = () => useContext(UpdateHeaderContext);

const HeaderProvider: FC<WithChildren> = ({ children }) => {
    const [headerContext, setHeaderContext] = useState<any>({
        pageTitle: "Page Title",
        searchQuery: "",
    });

    const updateHeaderContext = (context: any) => {
        setHeaderContext((prevContext: any) => ({
            ...prevContext,
            ...context,
        }));
    };

    return (
        <HeaderContext.Provider value={headerContext}>
            <UpdateHeaderContext.Provider value={updateHeaderContext}>
                {children}
            </UpdateHeaderContext.Provider>
        </HeaderContext.Provider>
    );
};

const SearchBar = forwardRef<HTMLInputElement, {}>((props, ref) => {
    const { searchQuery } = useHeaderContext();
    const updateHeaderContext = useUpdateHeaderContext();
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const { auth} = useAuth();
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const routeMap: Map<string, string> = new Map();
    if (auth?.role?.rolePermissions) {
        // const newPermissions = auth.role.rolePermissions
        //     .filter((x: any) => x.menuLocation === 'SIDEBAR' && x.interfaceLink !== '#')
        //     .map((obj: any) => routeMap.set(obj.interfaceType, "/" + obj.interfaceLink));
    }

    const toggleModal = () => setShowModal((prev) => !prev);

    const closeModal = () => setShowModal(false);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key.toLowerCase() === "q") {
            event.preventDefault();
            toggleModal();
        } else if (event.key === "Escape") {
            closeModal();
        } else if (event.key === "ArrowDown") {
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === "ArrowUp") {
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
            );
        } else if (event.key === "Enter" && activeSuggestionIndex >= 0) {
            handleSuggestionClick(suggestions[activeSuggestionIndex]);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [suggestions, activeSuggestionIndex]);

    useEffect(() => {
        if (showModal && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showModal]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        updateHeaderContext({ searchQuery: value });
        if (value.trim()) {
            const filteredSuggestions = Array.from(routeMap.keys() as IterableIterator<string>).filter((route) =>
                route.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setActiveSuggestionIndex(-1);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        if (suggestion) {
            const route = routeMap.get(suggestion);
            if (route) {
                navigate(`/app${route}`);
            }
            setSuggestions([]);
            updateHeaderContext({ searchQuery: "" });
            closeModal();
        }
    };

    return (
        <>
        <div className="search-container">
            <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={toggleModal}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            >
                <Icon icon={search} size={20} className="iconSearch" />
                {showTooltip && (
                    <div className="searchbar" >
                        Ctrl+Q
                    </div>
                )}
            </div>
        </div>
            {showModal && (
                <>
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 1000,
                            backdropFilter: "blur(4px)",
                        }}
                        onClick={closeModal}
                    />
                    <div
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            borderRadius: "30px",
                            padding: "20px",
                            width: "50%",
                            zIndex: 1001,
                        }}
                    >
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            }}
                            ref={inputRef}
                        />
                        {suggestions.length > 0 && (
                            <ul
                                style={{
                                    marginTop: "10px",
                                    listStyle: "none",
                                    padding: 0,
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    background: "white",
                                }}
                            >
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={suggestion}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            backgroundColor: index === activeSuggestionIndex ? "#6f42c1" : "white",
                                            color: index === activeSuggestionIndex ? "white" : "black",
                                        }}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )}
        </>
    );
});

export { HeaderProvider, HeaderContext, useHeaderContext, useUpdateHeaderContext, SearchBar };
