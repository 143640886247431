import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getCompanies,
 
  getCompanyServicesTopup,

  getTopUpPaymentsTransactions,
} from "../../../../services/PsidService";
import {
  ICompany,
  ICompanyService,
  IPsidStatusDetails,

} from "../../models/interfaces";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import ServicesSearchDropDown from "../../reusableComponents/ServicesSearchDropDown";
import { searchUtility } from "../../../../utils/searchUtility";
import {
  Modal,
  Container,
  Button,
} from "react-bootstrap";
import classes from "./style.module.css";
import FilterBox from "../../reusableComponents/FilterBox";
import ConsumerNumber from "../../reusableComponents/ConsumerNumber";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";
import { ITopupFilter } from "../../models/interfaces/ITopupFilter";
import { ITopUpPaymentTransactionResponse } from "../../models/interfaces/ITopUpPaymentTransactionResponse";


const TopupPayments = () => {
 
        const { auth } = useAuth();
      
        // const data = useMemo(() => MOCK_DATA, [])
      
        const updateHeaderContext = useUpdateHeaderContext();
        const _newDate = useMemo(
          () => moment(new Date()).format(dt.dbDateFormat),
          []
        );
        const [pageData, setPageData] = useState<any>({
          rowData: [],
          isLoading: false,
          totalPages: 10,
          totalRows: 0,
        });
        const [totalAmount, setTotalAmount] = useState(0);
        const [totalTopUpAmount,settotalTopUpAmount]=useState(0);
        const [totalLink1Amount,settotalLink1Amount]=useState(0);
        const [totalServiceChargesAmount,settotalServiceChargesAmount]=useState(0);
        const [currentPage, setCurrentPage] = useState(1);
        const [pageSize, setPageSize] = useState(10);
        
        const [companies, setCompanies] = useState<ICompany[]>([]);
        const [companyServices, setCompanyServices] = useState<ICompanyService[]>([]);
        const [loader, setLoader] = useState(false);
      
        const initialFilter = {
          startDate: _newDate,
          endDate: _newDate,
          companyId: "0",
          serviceId: "0",
          pageIndex: currentPage - 1,
          pageSize: pageSize,
          search: "",
        };
      
        const [filter, setFilter] = useState<ITopupFilter>({
          startDate: _newDate,
          endDate: _newDate,
          companyId: "0",
          serviceId: "0",
          pageIndex: currentPage - 1,
          pageSize: pageSize,
          search: ""
        });
        const [selectedFilter, setSelectedFilter] = useState<any>({ startDate: _newDate, endDate: _newDate });
        const [paymentTransaction, setPaymentTransaction] =
          useState<ITopUpPaymentTransactionResponse>();
      
        let columns: any = useMemo(() => {
          let columns = [
            {
              Header: "Sr#",
              accessor: "srno",
            },
            {
              Header: "PSID",
              accessor: "consumerNumber",
              Cell: ({ value }: { value: string }) => (
                <div style={{ color: "#6583E8", fontWeight: "bold" }}>{value}</div>
              ),
            },
            {
              Header: "Consumer Name",
              accessor: "consumerName",
            },
            {
              Header: "Service",
              accessor: "serviceName",
            },
            {
              Header: "Bank",
              accessor: "bank",
            },
            {
              Header: "Transaction Amount",
              accessor: "transactionAmount",
              Cell: ({ value }: { value: any }) => {
                const cleanedValue = value.replace(/,/g, '');
                const numberValue = parseFloat(cleanedValue);
                if (!isNaN(numberValue)) {
                  const integerValue = Math.trunc(numberValue);
                  return <strong>{integerValue.toLocaleString()}</strong>;
                }
                return <strong>{value}</strong>; 
              },
            },
            {
              Header: "Topup Amount",
              accessor: "topUpAmount",
            },
            {
              Header: "1Link Charges",
              accessor: "link1Charges",
            },
            {
              Header: "Service Charges",
              accessor: "serviceCharges",
            },
            {
              Header: "Transaction ID",
              accessor: "transactionAuthId",
            },
            {
              Header: "Transaction Date Time",
              accessor: "transactionDateTime",
            },
            // {
            //   Header: "Challan Amount",
            //   accessor: "challanAmount",
            //         Cell: ({ value }: CellProps<any>) => {
                        
            //             const formattedValue = Number(value).toLocaleString();
            //             const [integerPart, decimalPart] = formattedValue.split('.');
                  
            //             return (
            //               <>
            //                 <strong>{integerPart}</strong>
            //                 {decimalPart && <span>.{decimalPart}</span>}
            //               </>
            //             );
            //           }
            // },
          ];
      
          // if (
          //   ["ROLE_PITB_ADMIN", "ROLE_ADMIN", "PITB_FINANCE"].includes(
          //     auth?.role?.roleType
          //   )
          // ) {
          //   columns.push({
          //     Header: "PITB Charges",
          //     accessor: "pitbAmount",
          //   });
          //   // if (filter.transactionType !== "CARD") {
          //   //   columns.push({
          //   //     Header: "1LINK Charges",
          //   //     accessor: "link1Amount",
          //   //   });
          //   // }
          // }
      
          return columns;
        }, [auth?.role?.roleType, loader]);
        const [consumerNumber, setConsumerNumber] = useState<string>();
        const [showModal, setShowModal] = useState(false);
      
        const [psidStatus, setPsidStatus] = useState<IPsidStatusDetails>();

      
        const handleClose = () => {
          setShowModal(false);
        };
      
        useEffect(() => {
          updateHeaderContext({ pageTitle: "Topup Payments" });
          getCompanyList();
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
      
        useEffect(() => {
          getReportData();
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentPage, pageSize]);
      
        // useEffect(()=> {
        //     console.log(auth?.role?.roleType);
        // }, [paymentTransaction])
      
        const showCompanyDropdown = (): boolean => {
          return [
            "ROLE_PITB_ADMIN",
            "ROLE_ADMIN",
            "PITB_FINANCE",
            "ROLE_PITB_OPERATIONS",
          ].includes(auth?.role?.roleType);
        };
      
        const isDepartmentAdmin = (): boolean => {
          return auth?.role?.roleType == "ROLE_DEPARTMENT_ADMIN";
        };
      
        const getCompanyList = async () => {
          if (auth?.role.roleType == "ROLE_DEPARTMENT_ADMIN") {
            getCompanyServiceList(auth?.companyId);
          } else {
            const response = await getCompanies();
            const filteredContent = response.filter(item => item.getTopUpServices().length > 0);
            setCompanies(filteredContent);
          }
        };
      
        const getCompanyServiceList = async (companyId: number) => {
          const response = await getCompanyServicesTopup(companyId);
          setCompanyServices(response);
        };
      
        const getReportData = async (init: boolean = false) => {
          setLoader(true);

          setSelectedFilter(init ? initialFilter : filter);
          const response = await getTopUpPaymentsTransactions(
            init ? initialFilter : filter
          );
          if (response.status === 200) {
            const { content, totalAmount, totalTopUpAmount,totalLink1Amount ,totalServiceChargesAmount, totalRecord} = response.data;
            setTotalAmount(totalAmount);
            settotalTopUpAmount(totalTopUpAmount)
            settotalLink1Amount (totalLink1Amount)
           settotalServiceChargesAmount(totalServiceChargesAmount)
            const paymentTransactionObj: ITopUpPaymentTransactionResponse = content;
            setPaymentTransaction(paymentTransactionObj);
          let rowData: ITopUpPaymentTransactionResponse[] = Array.isArray(paymentTransactionObj) ? paymentTransactionObj:[];
        rowData = rowData.map((rd: ITopUpPaymentTransactionResponse, i: number): ITopUpPaymentTransactionResponse => {
        rd.srno = (currentPage - 1) * pageSize + i + 1;
        return rd;
         });   
            rowData = rowData.map((rd: any, i) => {
              rd.srno = (currentPage - 1) * pageSize + i + 1;
              rd.transactionAmount = Number(rd.transactionAmount).toLocaleString();
              if (rd.rudaAmount) {
                rd.rudaAmount = Number(rd.rudaAmount).toLocaleString();
              }
              if (rd.pitbAmount) {
                rd.pitbAmount = Number(rd.pitbAmount).toLocaleString();
              }
              if (rd.link1Amount) {
                rd.link1Amount = rd.link1Amount;
              }
              return rd;
            });
            if (Number( totalRecord ) <= filter.pageSize) {
              setCurrentPage(1);
            }
            setPageData((prevState: any) => ({
              ...prevState,
              isLoading: false,
              rowData: rowData,
              totalPages: 10,
              totalRows: Number(totalRecord),
            }));
          }
          setLoader(false);
        };
      
        // OnChange Handler
      
        const onChangeStartDate = (e: any) => {
          setCurrentPage(1);
          setFilter((filter: any) => ({
            ...filter,
            startDate: e.target.value,
            pageIndex: 0,
          }));
        };
      
        const onChangeEndDate = (e: any) => {
          setCurrentPage(1);
          setFilter((filter: any) => ({
            ...filter,
            endDate: e.target.value,
            pageIndex: 0,
          }));
        };
      
        const companyChangeHandler = (e: any) => {
          let companyId = "0";
          if (e.target.value && e.target.value != "all") {
            // const company = companies.find(c => c.getId() === e.target.value)
            // setCompany(company);
            companyId = e.target.value;
            getCompanyServiceList(e.target.value);
          } else {
            setCompanyServices([]);
          }
          setCurrentPage(1);
          setFilter((filter: any) => ({
            ...filter,
            companyId,
            serviceId: "0",
            pageIndex: 0,
          }));
        };
      
        const onConsumerNumberClick = async (row: any) => {
          setConsumerNumber(row?.consumerNumber);
          setPsidStatus({
            isConfirmed:row?.confirmed,
            isIntimated: row?.intimatedToDepartment
          });
          setShowModal(true);
        };
        
        function onSearchChange(value: any) {
          searchUtility(value, setFilter, companies, getCompanyServiceList);
        }
      
        const _initFilters = () => {
          setFilter(initialFilter);
          getReportData(true);
        };
      
        const statusChangeHandler = (e: any) => {
          const transactionType = e.target.value;
          setCurrentPage(1);
          setFilter((filter: any) => ({ ...filter, transactionType }));
        };
      
        const statusChangeHandlerPSIDType = (e: any) => {
          const psidType = e.target.value;
          setCurrentPage(1);
          setFilter((filter: any) => ({ ...filter, psidType }));
        };
      
        const serviceChangeHandler = (e: any) => {
          let serviceId = "0";
          if (e.target.value) {
            // const service = companyServices.find(s => s.getId() === e.target.value);
            serviceId = e.target.value;
            // setService(service)
          }
          setCurrentPage(1);
          setFilter((filter: any) => ({ ...filter, serviceId, pageIndex: 0 }));
        };
      
        const setPageIndex = (page: number) => {
          setCurrentPage(page);
          setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
        };
      
        const onLoadPageItems = (pageItem: number) => {
          setCurrentPage(1);
          setPageSize(pageItem);
          setFilter((filter: any) => ({
            ...filter,
            pageSize: pageItem,
            pageIndex: 0,
          }));
        };
      
        function exportExcel(): void {
          if (pageData.totalRows > 0) {
            const payload = {
              startDate: filter.startDate,
              endDate: filter.endDate,
              companyId: filter.companyId,
              serviceId: filter.serviceId,
              search: filter.search || -1,
            
              totalItems: pageData.totalRows,
            };
      
            const userName: any = auth?.username;
            console.log("Token", userName);
            window.location.assign(
              process.env.REACT_APP_API_URL +
                "/reporting/download/paymentTransactions/" +
                payload.startDate +
                "/" +
                payload.endDate +
                "/" +
                payload.serviceId +
                "/" +
                payload.companyId +
                "/" +
                payload.search +
                "/" +
                payload.totalItems +
                "/" +
                btoa(userName)
            );
          }
        }
      
        return (
          <div className="dashboard-middle-content">
            {loader || pageData.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </div>
            ) : (
              <>
              <div className="position-relative">
                <FilterBoxSelectedDate selectedFilter={selectedFilter}/>
              </div>
                <FilterBox
                  _initFilters={_initFilters}
                  getReportData={getReportData}
                  // exportExcel={exportExcel}
                >
                  <div className="w-100">
                    <ConsumerNumber
                      search={filter?.search}
                      onSearchChange={onSearchChange}
                    />
                  </div>
                  {showCompanyDropdown() && (
                    <div className="w-100">
                      <CompanySearchDropDown
                        companies={companies}
                        onChange={companyChangeHandler}
                        value={filter.companyId}
                      />
                    </div>
                  )}
                  <div className="w-100">
                    <ServicesSearchDropDown
                      companyServices={companyServices}
                      onChange={serviceChangeHandler}
                      value={filter.serviceId}
                    />
                  </div>
                  {/* <div className="w-100 form-group has-search">
                    <i
                      style={{ transform: "rotate(90deg)" }}
                      className="fa fa-exchange form-control-feedback"
                      aria-hidden="true"
                    ></i>
                    <select
                      className="form-control"
                      onChange={statusChangeHandler}
                      value={filter.transactionType}
                      id="transactionType"
                    >
                      {transactionType.map((ps: string) => (
                        <option key={ps} value={ps}>
                          {ps}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  {/* <div className="w-100">
                    <select
                      className="form-control"
                      onChange={statusChangeHandlerPSIDType}
                      value={filter.psidType}
                      id="psidType"
                    >
                      {psidType.map((ps: string) => (
                        <option key={ps} value={ps}>
                          {ps}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="w-100">
                    <input
                      className="dashboard-top-filter-input"
                      type="date"
                      value={filter?.startDate || ""}
                      onChange={onChangeStartDate}
                    />
                  </div>
                  <div className="w-100">
                    <input
                      className="dashboard-top-filter-input"
                      type="date"
                      value={filter?.endDate || ""}
                      onChange={onChangeEndDate}
                    />
                  </div>
                </FilterBox>
                {pageData?.rowData && (
                  <>
                    <div className="card">
                      <AppTable
                        columns={columns}
                        data={pageData.rowData.map((row: any) => ({
                          ...row,
                          consumerNumber: (
                            <div
                              onClick={() => onConsumerNumberClick(row)}
                              style={{
                                color: "#007bff",
                                cursor: "pointer",
                                minWidth:'120px'
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.textDecoration = "underline")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.textDecoration = "none")
                              }
                            >
                              {row.consumerNumber} {" "} {row?.intimatedToDepartment  ? (
                            <i
                              className="bi bi-check-circle"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i className="bi bi-clock" style={{ color: "red" }}></i>
                          )}
                            </div>
                          ),
                        }))}
                        isLoading={pageData.isLoading || loader}
                      />
                    </div>
                    <Pagination
                      totalRows={pageData.totalRows}
                      pageChangeHandler={setPageIndex}
                      rowsPerPage={filter.pageSize}
                      currentPage={currentPage}
                      pageItemChangeHandler={onLoadPageItems}
                    />
                    <div className="mb-4" />
                    <div className="row no___margin">
                      <div className="d-flex w-100 justify-content-between">
                         {showCompanyDropdown() && (
                          <div
                            style={{
                              backgroundColor: "rgba(233, 248, 242, 1)",
                            }}
                            className="p-2 text-center rounded col-md-3"
                          >
                            <span className={classes.textStyle}>
                              <b className={classes.textStyleBold}>
                                Transaction Amount
                              </b> {" "}
                              {Number(totalAmount).toLocaleString()} PKR
                            </span>
                          </div>
                        )}
                        <div
                          style={{
                            backgroundColor: "rgba(236, 241, 255, 1)",
                          }}
                          className="p-2 text-center rounded col-md-3"
                        >
                          <span className={classes.textStyle}>
                            <b className={classes.textStyleBold}>
                           Topup Amount
                            </b>{" "}
                            {Number(
                             totalTopUpAmount
                            ).toLocaleString()}{" "}
                            PKR
                          </span>
                        </div>
                          <div
                          style={{
                            backgroundColor: "rgba(236, 241, 255, 1)",
                          }}
                          className="p-2 text-center rounded col-md-3"
                        >
                          <span className={classes.textStyle}>
                            <b className={classes.textStyleBold}>
                            Service Charges
                            </b>{" "}
                            {Number(
                          totalServiceChargesAmount
                            ).toLocaleString()}{" "}
                            PKR
                          </span>
                        </div>
                     
                        
                          <div
                            style={{
                              backgroundColor: "rgba(82, 245, 255, 0.12)",
                            }}
                            className="p-2 text-center rounded"
                          >
                            <span className={classes.textStyle}>
                              <b className={classes.textStyleBold}>
                                1LINK Charges
                              </b>{" "}
                              {Number(
                           totalLink1Amount
                              ).toLocaleString()}{" "}
                              PKR{" "}
                            </span>
                          </div>
                   
                      </div>
                      {/* {paymentTransaction?.shouldShowBankInfo && (
                        <div className="col-md-6">
                          <div>
                            <span>
                              <b>Account Title : </b>
                              {paymentTransaction?.accountTitle}
                            </span>
                          </div>
                          <div>
                            <span>
                              <b>Account Number :</b>{" "}
                              {paymentTransaction?.accountNumber}
                            </span>
                          </div>
                          <div>
                            <span>
                              <b>IBAN :</b> {paymentTransaction?.iban}
                            </span>
                          </div>
                          <div>
                            <span>
                              <b>Bank Name :</b> {paymentTransaction?.bank}
                            </span>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </>
                )}
                <Modal
                  show={showModal}
                  onHide={handleClose}
                  dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-lg"
                >
                  <Modal.Header>
                    <Modal.Title>PSID status</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container className="d-flex justify-content-between">
                      <div>
                        <div>
                          <strong className="w-25 text-center">Consumer Number</strong>
                        </div>
                        <div className="text-center mt-2">{consumerNumber}</div>
                      </div>
                      <div>
                        <div>
                          <strong className="w-25 text-center">Confirmation</strong>
                        </div>
                        <div className="text-center mt-2">
                          {psidStatus?.isConfirmed ? (
                            <i
                              className="bi bi-check-circle"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                          )}
                        </div>
                      </div>
                      <div className="w-25 text-center">
                        <div>
                          <strong>Intimation</strong>
                        </div>
                        <div className="text-center mt-2">
                          {psidStatus?.isIntimated ? (
                            <i
                              className="bi bi-check-circle"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                          )}
                        </div>
                      </div>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </div>
        );
        
      }


export default TopupPayments