import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getTransactionSummaryAccountWise,

} from "../../../../services/PsidService";
import {
  ITransactionSummaryResponse,
  ITransactionSummaryFilter,
  ICompanyTransactionSummary,
  IServiceSettlementResponse,
} from "../../models/interfaces";

import { IServiceBifurcationResponse } from "../../models/interfaces/IServiceBifurcationResponse";
import { getCompanyTransactionsSummary } from "../../../../services/CompanySettlement";
import FilterBox from "../../reusableComponents/FilterBox";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";

function CompanySettlements() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  // const transactionType: any = useMemo(() => ["1BILL", "CARD"], []);

  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
  };

  const [filter, setFilter] = useState<ITransactionSummaryFilter>({
    startDate: _newDate,
    endDate: _newDate,
  });
  const [selectedFilter, setSelectedFilter] = useState<any>({
    startDate: _newDate,
    endDate: _newDate,
  });

  // const [_, setTransactionSummary] = useState<ITransactionSummaryResponse[]>();
  const today = new Date().toISOString().split("T")[0];
  const [companyTransactionSummary, setCompanyTransactionSummary] =
    useState<ICompanyTransactionSummary[]>();
  const [totalChallanAmount, setTotalChallanAmount] = useState<Number>(0);
  const [totalPitbCharges, setTotalPitbCharges] = useState<Number>(0);
  const [transactionBifurcation, setTransactionBifurcation] =
    useState<IServiceBifurcationResponse[]>();
  const [serviceBifurcation, setServiceBifurcation] = useState<string>("");
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    updateHeaderContext({ pageTitle: "Company Settlements" });
    getReportData();
  }, []);

  const getReportData = async (init: boolean = false) => {
    setLoader(true);
    setShow(false);
    setCompanyTransactionSummary([]);
    setTotalChallanAmount(0);
  
    try {
      setSelectedFilter(init ? initialFilter : filter);
      const response = await getCompanyTransactionsSummary(
        init ? initialFilter : filter
      );
  
      if (response.status === 200) {
        const { content } = response.data;
        const transactionSummaryObj: ITransactionSummaryResponse[] = content;
        let totalChallan = 0;
        let totalCharges = 0;
        let companySummaryList: ICompanyTransactionSummary[] = [];
  
        transactionSummaryObj.forEach((obj) => {
          const existedCompanySummary = companySummaryList.find(
            (ecs) => ecs.companyId === obj.companyId
          );
  
          if (existedCompanySummary) {
            existedCompanySummary.companyTotalChallanAmount += Number(
              obj.challanAmount
            );
            existedCompanySummary.companyTotalPitbCharges += Number(
              obj.pitbCharges
            );
            existedCompanySummary.serviceTransactionSummary.push({
              serviceName: obj.serviceName,
              settlementMode: obj.settlementMode,
              challanAmount: obj.challanAmount,
              pitbCharges: obj.pitbCharges,
              serviceId: obj.serviceId,
            });
          } else {
            companySummaryList.push({
              companyId: obj.companyId,
              companyName: obj.companyName,
              companyTotalChallanAmount: Number(obj.challanAmount),
              companyTotalPitbCharges: Number(obj.pitbCharges),
              serviceTransactionSummary: [
                {
                  serviceName: obj.serviceName,
                  settlementMode: obj.settlementMode,
                  challanAmount: obj.challanAmount,
                  pitbCharges: obj.pitbCharges,
                  serviceId: obj.serviceId,
                },
              ],
            });
          }
  
          totalChallan += obj.challanAmount ? Number(obj.challanAmount) : 0;
          totalCharges += obj.pitbCharges ? Number(obj.pitbCharges) : 0;
        });
  
        setTotalChallanAmount(totalChallan);
        setTotalPitbCharges(totalCharges);
        setCompanyTransactionSummary(companySummaryList);
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoader(false);
    }
  };
  

  const handleShow = async (companyId: number) => {
    setTransactionBifurcation([]);
    // setServiceBifurcation(serviceName);
    const response = await getTransactionSummaryAccountWise({
      startDate: filter.startDate,
      endDate: filter.endDate,
      companyId: companyId,
    });
    if (response.status === 200) {
      const data = response.data.content;
      setTransactionBifurcation(data);
      // console.log(data);
      setShow(true);
      setServiceBifurcation(String(companyId));
    }
  };

  // const statusChangeHandler = (e: any) => {
  //   const transactionType = e.target.value;
  //   setFilter((filter: any) => ({ ...filter, transactionType }));
  // };

  const onChangeStartDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  var rows =
    companyTransactionSummary &&
    companyTransactionSummary.length > 0 &&
    companyTransactionSummary.map(
      (row: ICompanyTransactionSummary, index: any) => {
        return (
          <>
            <tr
              key={index + 50}
              onClick={() => {
                if (serviceBifurcation !== String(row.companyId))
                  handleShow(row.companyId);
                else if (serviceBifurcation === String(row.companyId) && !show)
                  handleShow(row.companyId);
                else setShow(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <td>
                <b className="mr-4">{row.companyName}</b>
                {show && serviceBifurcation === String(row.companyId) ? (
                  <i className="fa fa-angle-up" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                )}
              </td>
            </tr>
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 0 &&
              row.serviceTransactionSummary.map(
                (crow: IServiceSettlementResponse, cindex: any) => {
                  return (
                    <tr key={cindex}>
                      <td></td>
                      <td>{crow.serviceName}</td>
                      <td>{Number(crow.challanAmount).toLocaleString()}</td>
                    </tr>
                  );
                }
              )}
            {show && serviceBifurcation && (
              <tr>
                <td colSpan={4}>
                  <table>
                    <thead>
                      <tr>
                        <th>Account Title</th>
                        <th>Account Number</th>
                        <th>Settlement Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionBifurcation?.length != undefined &&
                        transactionBifurcation.map(
                          (tbRow: IServiceBifurcationResponse, index: any) => (
                            <tr>
                              <td>{tbRow.accountTitle}</td>
                              <td>{tbRow.accountNumber}</td>
                              <td className="text-center">
                                {tbRow.challanAmount.toLocaleString()}
                              </td>
                            </tr>
                          )
                        )}
                      {transactionBifurcation?.length == 0 && (
                        <tr>
                          <td />
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No record found.
                          </div>
                          <td />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 1 && (
                <tr key={index}>
                  <td colSpan={2} className="text-right">
                    <b>Company Total</b>
                  </td>
                  <td>{row.companyTotalChallanAmount.toLocaleString()}</td>
                </tr>
              )}
          </>
        );
      }
    );

    return (
      <div className="dashboard-middle-content">
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        ) : (
          <>
          <div className="position-relative">
            <FilterBoxSelectedDate selectedFilter={selectedFilter}/>
          </div>
            <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
              <div className="w-100">
                <input
                  className="dashboard-top-filter-input mb-2"
                  type="date"
                  value={filter?.startDate || ""}
                  onChange={onChangeStartDate}
                  max={today}
                />
              </div>
              <div className="w-100">
                <input
                  className="dashboard-top-filter-input mb-2"
                  type="date"
                  value={filter?.endDate || ""}
                  onChange={onChangeEndDate}
                  max={today}
                />
              </div>
            </FilterBox>
            {companyTransactionSummary?.length ? (
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Service Name</th>
                      <th>Company Charges</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            ) : (
              <p>No Record Found</p>
            )}
          </>
        )}
      </div>
    );
    
    
}

export default CompanySettlements;
