import React from 'react';
import classes from './styles.module.css';
interface DashboardServiceBoxProps {
  title: string;
  amountCollected: string;
  nOfTransactions: string;
  companyId: string;
}
const DashboardServiceBox = ({
  title,
  amountCollected,
  nOfTransactions,
  companyId,
}: DashboardServiceBoxProps) => {
  const logoPath = "/images/default.png";
  return (
    <div className={classes.dashboardServiceBox1}>
      <div className={classes.dashboardServiceBox3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: '18px',
              // whiteSpace: 'nowrap',
              overflow: 'hidden',
              // textOverflow: 'ellipsis',
            }}
            className="mt-2"
            title={title}
          >
            {title}
          </div>
          {/* <img
            src={logoPath}
            alt="company-logo"
            className="img-responsive"
            width={35}
            height={35}
            style={{ marginLeft: '10px', height:'auto' , width:'60px' }}
          /> */}
        </div>
      </div>
      <div className={classes.dashboardServiceBox2}>
        <div
          style={{ fontWeight: 500, fontSize: '14px', color: '#00000080' }}
          className="mt-4"
        >
          Amount Collected
        </div>
        <div style={{ fontWeight: 600, fontSize: '18px' }}>{amountCollected}</div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="mt-2"
      >
        <div style={{ fontWeight: 500, fontSize: '14px', color: '#00000080' }}>
          No of Transactions
        </div>
        <div style={{ fontWeight: 600, fontSize: '18px' }}>{nOfTransactions}</div>
      </div>
    </div>
  );
};
export default DashboardServiceBox;



