const FilterBoxSelectedDate = ({
    selectedFilter,
    isDashboard = false,
    isInitimation = false,
    isServiceWiseSettlement = false,
 
}: any) => {

//   useEffect(() => {
//     console.log("filter : "+selectedFilter.toString);
//   }, [selectedFilter]);

//   const onFilterBoxClick = () => {
//     getReportData();
//     setShowFilterBox(false);
//   };

//   const onResetBoxClick = () => {
//     _initFilters();
//     setShowFilterBox(false);
//   };

//   const toggleFilterBoxClick = () => {
//     if (window.innerWidth <= 768) {
//       setShowFilterBox(!showFilterBox);
//     }
//   };
  return (
    <>
    <div className={isDashboard ? 'selected-dates' : 'selected-dates-reports'} style={isInitimation? {'right':'260px'}:{}}>
        <b>Filtered Date:</b> <span style={{color:'#6583E8'}}>{isServiceWiseSettlement ? selectedFilter.toDate: selectedFilter.startDate}</span> {!isServiceWiseSettlement && <>To <span style={{color:'#6583E8'}}>{selectedFilter.endDate}</span></>}
    </div>
    </>
  );
};
export default FilterBoxSelectedDate;
