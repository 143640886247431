import {Route, Routes} from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import Dashbord from '../modules/app/components/Dashbord'
import BasicTable from '../modules/app/components/reports/BasicTable'
import PaymentTransaction from '../modules/app/components/reports/PaymentTransaction'
import PsidList from '../modules/app/components/reports/PsidList'
import Users from '../modules/app/components/user-management/Users'
import UserInterfaces from '../modules/app/components/UserInterfaces'
import UserPermissions from '../modules/app/components/UserPermissions'
import UserRole from '../modules/app/components/UserRole'
import CompanyService from '../modules/app/components/utilities/CompanyService'
import GeneratePsid from '../modules/app/components/utilities/GeneratePsid'
import RegisteredRequest from '../modules/app/components/utilities/RegisteredRequest'
import DailySettlment from '../modules/app/components/utilities/DailySettlment'
import ChangePassword from '../modules/app/components/utilities/ChangePassword'
import ServiceWiseSettlement from '../modules/app/components/reports/ServiceWiseSettlement'
import TransactionSummary from '../modules/app/components/reports/TransactionSummary'
import IntimationFailiure from '../modules/app/components/reports/IntimationFailure'
import CompanySettlements from '../modules/app/components/reports/CompanySettlements'
import LinkConfirmation from '../modules/app/components/utilities/LinkConfirmation'
import RevertTransaction from '../modules/app/components/reports/RevertTransaction'
import RevertedTransaction from '../modules/app/components/reports/RevertedTransactions'
import ActivatePayment from '../modules/app/components/config/ActivatePayment'
import ActivateIntimation from '../modules/app/components/config/ActivateIntimation'
import PageNotFound from '../PageNotFound'
import PaymentInquiry from '../modules/app/components/utilities/PaymentInquiry'
import TopupPayments from '../modules/app/components/reports/TopupPayments'
import  PsidDetails from '../../../src/app/modules/app/components/reports/PsidDetails'
const PrivateRoutes = () => (
  <Routes>
    <Route element={<AppLayout />}>
      <Route path='dashboard' element={<Dashbord />} />
      <Route path='payment-transaction' element={<PaymentTransaction />} />
      <Route path='service-wise-settlement' element={<ServiceWiseSettlement />} />
      <Route path='transactions-summary' element={<TransactionSummary />} />
      <Route path='psid-list' element={<PsidList />} />
      <Route path='link-confirmation' element={<LinkConfirmation />} />
      <Route path='intimation-failure' element={<IntimationFailiure />} />
      <Route path='generate-psids' element={<GeneratePsid />} />
      <Route path='daily-settlement' element={<DailySettlment />} />
      <Route path='compnay-services' element={<CompanyService />} />
      <Route path='registered-requests' element={<RegisteredRequest />} />
      <Route path='permissions' element={<UserPermissions />} />
      <Route path='interfaces' element={<UserInterfaces />} />
      <Route path='roles' element={<UserRole />} />
      <Route path='users' element={<Users />} />
      <Route path='profile/update-password' element={<ChangePassword />} />
      <Route path='basic-table' element={<BasicTable />} />
      <Route path='company-settlements' element={<CompanySettlements />} />
      <Route path='revertTransactions' element={<RevertTransaction />} />
      <Route path='revertReport' element={<RevertedTransaction />} />
      <Route path='activate-intimations' element={<ActivateIntimation />} />
      <Route path='activate-payments' element={<ActivatePayment />} />
      <Route path='payment-inquiry' element={<PaymentInquiry />} />
      <Route path='topup-payment' element={<TopupPayments/>} />

      <Route path='psiddetail' element={<PsidDetails/>} />
      <Route index element={<Dashbord />} />
    </Route>
    <Route path='*' element={<PageNotFound />} />
  </Routes>
)

export {PrivateRoutes}