import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getCompanies,
  getServiceWiseSettlement,
} from "../../../../services/PsidService";
import {
  ICompany,
  IServiceSettlementResponse,
  IServiceSettlementFilter,
} from "../../models/interfaces";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import FilterBox from "../../reusableComponents/FilterBox";
import uilbill from"../../../../../assets/images/filter_icons/uil_bill.png";
import Swal from "sweetalert2";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";

function ServiceWiseSettlement() {
  const { auth } = useAuth();

  // const data = useMemo(() => MOCK_DATA, [])
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const today = new Date().toISOString().split("T")[0];
  const transactionType: any = useMemo(() => ["1BILL", "CARD"], []);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const initialFilter = {
    toDate: _newDate,
    companyId: "",
    transactionType: "1BILL",
  };
  const [filter, setFilter] = useState<IServiceSettlementFilter>({
    toDate: _newDate,
    companyId: "",
    transactionType: "1BILL",
  });
  const [selectedFilter, setSelectedFilter] = useState<any>({ toDate: _newDate });
  const [serviceSettlement, setServiceSettlement] =
    useState<IServiceSettlementResponse[]>();
  useEffect(() => {
    updateHeaderContext({ pageTitle: "Service Wise Settlement" });
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   getReportData();
  // }, [companies]);

  // useEffect(()=> {
  //     console.log(auth?.role?.roleType);
  // }, [paymentTransaction])

  const showCompanyDropdown = (): boolean => {
    return ["ROLE_PITB_ADMIN", "ROLE_ADMIN", "PITB_FINANCE"].includes(
      auth?.role?.roleType
    );
  };

  const isDepartmentAdmin = (): boolean => {
    return auth?.role?.roleType == "ROLE_DEPARTMENT_ADMIN";
  };

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const getReportData = async (init: boolean = false) => {
    if(filter.companyId === ""){
      Swal.fire("Error","Select a Company to view details","error");
      return
    }
    
    setSelectedFilter(init ? initialFilter : filter);
    const response = await getServiceWiseSettlement(
      init ? initialFilter : filter
    );
    if (response.status === 200) {
      const { content } = response.data;
      const serviceSettlementObj: IServiceSettlementResponse[] = content;
      setServiceSettlement(serviceSettlementObj);
    }
  };

  // OnChange Handler

  const onChangeToDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, toDate: e.target.value }));
  };

  const companyChangeHandler = (e: any) => {
    let companyId = "0";
    if (e.target.value) {
      // const company = companies.find(c => c.getId() === e.target.value)
      // setCompany(company);
      companyId = e.target.value;
    }
    setFilter((filter: any) => ({ ...filter, companyId }));
  };

  function exportExcel(): void {}

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  var rows =
    serviceSettlement &&
    serviceSettlement.length > 0 &&
    serviceSettlement.map((row: IServiceSettlementResponse, index: any) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row.serviceName}</td>
          <td>{row.settlementMode}</td>
          {/* <td>{row.transactionDate}</td> */}
          <td><strong>{Number(row.challanAmount).toLocaleString()}</strong></td>
          <td>{Number(row.pitbCharges).toLocaleString()}</td>
        </tr>
      );
    });

  return (
    <div className="dashboard-middle-content">
      <div className="position-relative">
            <FilterBoxSelectedDate selectedFilter={selectedFilter} isServiceWiseSettlement={true}/>
      </div>
      <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
        <div className="w-100">
          <input
            className="dashboard-top-filter-input mb-2"
            type="date"
            value={filter?.toDate || ""}
            onChange={onChangeToDate}
            max={today}
          />
        </div>
        {showCompanyDropdown() && (
          <div className="w-100">
            <CompanySearchDropDown
              companies={companies}
              onChange={companyChangeHandler}
              value={filter.companyId ? filter.companyId : ""}
              allCompanies={false}
            />
          </div>
        )}
        <div className="w-100 mb-2 position-relative">
          <img className="position-absoluteIcon" src={uilbill} alt="position-absoluteIcon"/>
          <select
            className="form-control paddingSelect"
            onChange={statusChangeHandler}
            value={filter.transactionType}
          >
            {transactionType.map((ps: string) => (
              <option key={ps} value={ps}>
                {ps}
              </option>
            ))}
          </select>
        </div>
      </FilterBox>
      {
        <div className="card">
          <div>
            <table>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Service Name</th>
                  <th>Settlement Mode</th>
                  {/* <th>Transaction Date</th> */}
                  <th>Challan Amount</th>
                  <th>PITB Charges</th>
                </tr>
              </thead>
              <tbody>
                {serviceSettlement && serviceSettlement?.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                      >
                        No record found.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
}

export default ServiceWiseSettlement;
