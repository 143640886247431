import { useEffect, useRef, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "../../assets/css/dashboard.css";
import { useAuth } from "../modules/auth/core/Auth";
import { HeaderContext, HeaderProvider, SearchBar } from "../contexts/HeaderContext";
import classes from "./style.module.css";
import { icons } from "../utils/Utility";

export default function AppLayout() {
  const { auth, logout } = useAuth();
  const location = useLocation();
  const [menuIndexes, setMenuIndexes] = useState<number>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const router = useNavigate();
  const { pathname } = useLocation();
  const searchBarRef = useRef<HTMLInputElement>(null); 

  const toggleSidebar = () => {
    if (!isSidebarOpen) setMenuIndexes(undefined);
    setIsSidebarOpen((prev) => !prev);
  };

  const perpareMenus = () => {
    const role: any = auth?.role;

    const rolePermissions: any = role.rolePermissions;
    const interfaces: any[] =
      rolePermissions &&
      rolePermissions.filter((rp: any) => rp.parentInterfaceId === null);
    let interfaceList: any[] = [];
    interfaces &&
      interfaces.forEach((i: any) => {
        const perm = i.permissions.find(
          (ip: any) => ip.permissionType === "VIEW"
        );
        if (perm) {
          let menu = i;
          const subInterfaces = rolePermissions.filter(
            (rp: any) => rp.parentInterfaceId === i.interfaceId
          );
          let subInterfacesList: any[] = [];
          subInterfaces.forEach((si: any) => {
            const sp = si.permissions.find(
              (ip: any) => ip.permissionType === "VIEW"
            );
            if (sp) {
              si.smactive = false;
              subInterfacesList.push(si);
            }
          });
          menu.haveSubMenu = subInterfacesList.length > 0;
          menu.collapse = false;
          menu.submenus = subInterfacesList;
          interfaceList.push(menu);
        }
      });
    interfaceList = interfaceList.sort((a, b) =>
      a.sequence < b.sequence ? -1 : 1
    );
    setMenuLinks(interfaceList);
  };

  const menuClick = (
    menuType: string,
    menuObj: any,
    index: number,
    pid = 0
  ) => {
    if (menuType === "parent") {
      if (index === menuIndexes) setMenuIndexes(undefined);
      else setMenuIndexes(index);
    }
    const menu = menuObj;
    const menuLinkList = menuLinks;
    if (menuType === "parent") {
      menu.collapse = !menu.collapse;
      menuLinkList[index] = menu;
    } else {
      menu.active = !menu.active;
      const parentMenuIndex = menuLinkList.findIndex(
        (m) => m.interfaceId === pid
      );
      menuLinkList[parentMenuIndex].submenus[index] = menu;
    }
    setMenuLinks(menuLinkList);
  };

  const [menuLinks, setMenuLinks] = useState<any[]>([]);

  const links = menuLinks && menuLinks.length > 0 && (  
    <div className="list-group-flush">
      {menuLinks.map((m: any, mindex: number) => (
        <div key={mindex}>
          {!isSidebarOpen ? (
            <div key={m.interfaceId}>
              {!m.haveSubMenu ? (
                <div className={classes.sidebarDiv1}>
                  <div className={classes.sidebarDiv2}>
                    {typeof icons[mindex] === "string" ? (
                      <i className={icons[mindex] as string} style={{ color: "#fff" }}></i>
                    ) : (
                      <img
                        src={(icons[mindex] as { src: string; alt: string }).src}
                        alt={(icons[mindex] as { src: string; alt: string }).alt}
                        style={{ width: "24px", height: "24px", background: "#6754A8" }}
                      />
                    )}
                  </div>
                  <Link
                    className="list-group-item-action-main"
                    to={`/app/${m.interfaceLink}`}
                    style={{ fontWeight: 400 }}
                  >
                    {m.interfaceType}
                  </Link>
                </div>
              ) : (
                <>
                  <div className={classes.sidebarDiv1}>
                    <div className={classes.sidebarDiv2}>
                      {typeof icons[mindex] === "string" ? (
                        <i className={icons[mindex] as string} style={{ color: "#fff" }}></i>
                      ) : (
                        <img
                          src={(icons[mindex] as { src: string; alt: string }).src}
                          alt={(icons[mindex] as { src: string; alt: string }).alt}
                          style={{ width: "24px", height: "24px" }}
                        />
                      )}
                    </div>
                    <div
                      data-toggle="collapse"
                      data-target={"#demo" + m.interfaceId}
                      className={`list-group-item-action-main cp ${location.pathname.includes(`/app/${m.interfaceLink}`) ? 'active' : ''}`}
                      onClick={() => menuClick("parent", m, mindex)}
                      style={{ width: "90%", fontWeight: 400 }}
                    >
                      {m.interfaceType}
                      {mindex === menuIndexes ? (
                        <i className="fa fa-angle-up mt-2" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-angle-down mt-2" aria-hidden="true"></i>
                      )}
                    </div>
                  </div>
                  {m.haveSubMenu && menuIndexes === mindex && (
                    <div
                      id={"demo" + m.interfaceId}
                      className="collapsed left-bar-main-collapse"
                    >
                      {m.submenus.map((sm: any, smindex: number) => (
                        <Link
                          key={sm.interfaceId}
                          className={`left-bar-submenu ${location.pathname === `/app/${sm.interfaceLink}` ? 'active' : ''}`}
                          to={`/app/${sm.interfaceLink}`}
                          onClick={() =>
                            menuClick("child", sm, smindex, m.interfaceId)
                          }
                          style={{
                            color:
                              pathname === `/app/${sm.interfaceLink}` ? "#ffff" : "",
                            borderLeftColor:
                              pathname === `/app/${sm.interfaceLink}` ? "white" : "",
                          }}
                        >
                          {sm.interfaceType}
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              )}
              
            </div>
          ) : (
            <>
              <div className={classes.sidBarCloseIcons}>
                <div />
                {typeof icons[mindex] === "string" ? (
                  <i
                    className={icons[mindex] as string}
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => {
                      menuClick("parent", m, mindex);
                      if (mindex === 0) router(`/app/${m.interfaceLink}`);
                    }}
                  ></i>
                ) : (
                  <img
                    src={(icons[mindex] as { src: string; alt: string }).src}
                    alt={(icons[mindex] as { src: string; alt: string }).alt}
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    onClick={() => {
                      menuClick("parent", m, mindex);
                      if (mindex === 0) router(`/app/${m.interfaceLink}`);
                    }}
                  />
                )}
                {m.haveSubMenu && menuIndexes === mindex && (
                  <div
                    id={"demo" + m.interfaceId}
                    className={`collapsed left-bar-main-collapse ${classes.nestedDiv}`}
                    style={{
                      position: "absolute",
                      left: 240,
                      padding: 0,
                    }}
                  >
                    {m.submenus.map((sm: any, smindex: number) => (
                      <Link
                        key={sm.interfaceId}
                        to={"/app/" + sm.interfaceLink}
                        onClick={(e: any) => {
                          menuClick("child", sm, smindex, m.interfaceId);
                          e.stopPropagation();
                        }}
                        className={`left-bar-submenu ${location.pathname === `/app/${sm.interfaceLink}` ? 'active' : ''}`}
                        style={{
                          color:
                            pathname === `/app/${sm.interfaceLink}` ? "" : "black",
                          borderLeft:
                            pathname === `/app/${sm.interfaceLink}`
                              ? "2px solid black"
                              : "2px solid #dbd6ea",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                          padding: "10px",
                        }}
                      >
                        <div onClick={() => setMenuIndexes(undefined)}>
                          {sm.interfaceType}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
                            
              </div>
            </>
          )}
          
        </div>
      ))}
    </div>
    
  );
  useEffect(() => {
    perpareMenus();
  }, []);
  return (
    <HeaderProvider>
      <div style={{ backgroundColor: "#56419e" }}>
        <div className={`wrapper ${isSidebarOpen ? "toggled" : ""}`}>
          <div className={`sidebar-wrapper`}>
            <div className="sidebar-heading clearfix">
              <div>
                <Link to="/app/dashboard">
                  {!isSidebarOpen ? (
                    <img
                      src="../../images/Logo_up.png"
                      alt="logo"
                      className="img-responsive"
                      width={137}
                      height={27}
                    />
                  ) : (
                    <div className={classes.nestedDiv1}>
                      <img
                        src="../../favicon.png"
                        alt="logo"
                        className="img-responsive"
                        width={50}
                      />
                    </div>
                  )}
                </Link>
              </div>
            </div>
            {links}
          </div>
          <div className="page-content-wrapper">
            <HeaderContext.Consumer>
              {(value) => (
                <nav className="navbar navbar-expand-lg navbar-light nav-bg dashboard-fixed-top">
                  <div
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    className="trapezoid"
                    id="menu-toggle"
                    onClick={toggleSidebar}
                  >
                    {isSidebarOpen ? (
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    )}
                  </div>
                  <div
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    className="dashboard-header"
                    style={{ width: "50%" }}
                  >
                    {value.pageTitle}
                  </div>
                  <div style={{ display: "flex", width: "50%" }}>
                    <div style={{ marginLeft: "auto", marginRight: "1rem", position: "relative" }}>
                      <SearchBar ref={searchBarRef} />
                    </div>
                  </div>
                  <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-responsive">
                      <li className="nav-item dropdown">
                      <div
                 data-aos="fade-right"
              data-aos-duration="2000"
               className="nav-link dropdown-toggle cp welcome-message newChanges"
              id="navbarDropdown"
              role="button"
               data-toggle="dropdown"
                  aria-haspopup="true"
                aria-expanded="false"
           style={{ display: "flex", alignItems: "center" }}
            >
              <div className={classes.profileIcon}>
              <i className="fa fa-user" aria-hidden="true"></i>
                     </div>
               <div style={{ marginRight: "8px" }}>
               <div>{auth?.fullName}</div>
               <div className={classes?.roleStyles}>
                  {auth?.role?.roleType.split("_")?.[1]}
                 </div>
                 </div>
                 <div>
             <i className="fa fa-angle-down" aria-hidden="true"></i>
           </div>
            </div>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            to="/app/profile/update-password"
                            className="list-group-item-action-main cp"
                          >
                            <i className="fa fa-key" aria-hidden="true"></i>
                            Change Password
                          </Link>
                          <span
                            onClick={logout}
                            className="list-group-item-action-main cp"
                          >
                            <i
                              style={{
                                transform: "rotate(180deg)",
                                color: "#FF4F4F",
                              }}
                              className="fa fa-sign-out"
                              aria-hidden="true"
                            ></i>
                            Logout
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              )}
            </HeaderContext.Consumer>
            <motion.div
              key={pathname}
              initial="initial"
              animate="in"
              variants={{
                initial: { opacity: 0 },
                in: { opacity: 1 },
                out: { opacity: 0 },
              }}
              transition={{
                type: "tween",
                ease: "linear",
                duration: 0.5,
              }}
              className={classes.parentNode}
            >
 
              <Outlet />
             
            </motion.div>
        
          </div>
      
        </div>
    
      </div>
      <div className="footer_bottom text-center py-4 justify-content-center align-items-cente footerclass">
      Powered By Punjab Information Technology Board &copy; 2025
      </div>

    </HeaderProvider>
 
  );
}
