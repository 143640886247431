import moment from "moment";
import { useMemo, useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { ICompany } from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import { getCompanies } from "../../../../services/PsidService";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import ResetIcon from "../../../../../assets/svgs/resetIcon.svg";
import { useAuth } from "../../../auth/core/Auth";

import {
  IntimationFailureResponse,
  IntimationFilter,
  IntimationTriesDetailResponse,
} from "../../models/interfaces/IntimationFailureReport";
import {
  getIntimationFailureReport,
  getIntimationTriesDetail,
  resetIntimations,
} from "../../../../services/IntimationFailure";
import {
  Button,
  Card,
  Container,
  Modal,
} from "react-bootstrap";
import { numberWithCommas } from "../../../../utils/Utility";
import SelectSearchDropDown from "../../reusableComponents/SelectSearchDropDown";
import FilterBox from "../../reusableComponents/FilterBox";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";

function IntimationFailiure() {
  const { auth } = useAuth();

  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [intimationTriesDetail, setIntimationTriesDetail] =
    useState<IntimationTriesDetailResponse[]>();
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [loader, setLoader] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const initialFilter: IntimationFilter = {
    startDate: _newDate,
    endDate: _newDate,
    companyServiceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    consumerNumber: "",
  };
  const isFirstRender = useRef(true);
  const [filter, setFilter] = useState<IntimationFilter>(initialFilter);
  const [selectedFilter, setSelectedFilter] = useState<any>({
      startDate: _newDate,
      endDate: _newDate,
    });
  const [intimations, setIntimations] = useState<IntimationFailureResponse[]>();
  const [showModal, setShowModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const getCompanyOptions = () => {
    return companies.map((company) => ({
      label: company.getCompanyName(),
      options: company.getServices().map((service) => ({
        value: service.getId(),
        label: service.getServiceName(),
      })),
    }));
  };

  const handleSelect = (id: number) => {
    if (selectedRows.includes(id)) {
      const newSelections = selectedRows.filter((rowId) => {
        return rowId !== id;
      });
      setSelectedRows(newSelections);
    } else {
      setSelectedRows((prev) => [...prev, Number(id)]);
    }
  };

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allIds = pageData.rowData.map((row: any) => row.id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const resetIntimation = async () => {
    if (selectedRows.length < 1) {
      Swal.fire({
        icon: "error",
        text: "Please select at least one intimation to reset",
      });
      return;
    }
  
    setLoader(true); 
    try {
      const response = await resetIntimations(selectedRows);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Intimations Reset",
          text: "The selected intimation(s) have been reset successfully",
        });
        await getReportData(); 
      } else {
        Swal.fire({
          icon: "error",
          title: "Intimations Reset Failed",
          text: "An error occurred while resetting the intimation(s)",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Intimations Reset Failed",
        text: "An error occurred while resetting the intimation(s)",
      });
    } finally {
      setLoader(false); 
      setSelectedRows([]); 
    }
  };
  

  const handleClose = () => {
    setShowModal(false);
  };

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const onConsumerNumberClick = async (id: number) => {
    setLoader(true);
    const intimationTriesDetail = await getIntimationTriesDetail(id);
    setLoader(false);
    setIntimationTriesDetail(intimationTriesDetail?.data?.content);
    setShowModal(true);
  };

  const getReportData = async (init: boolean = false) => {
    if (moment(filter.startDate).isAfter(filter.endDate)) {
      Swal.fire({
        icon: "error",
        title: "Date Range Error",
        text: "From Date can not be greater than To Date",
      });
      return;
    }
    setPageData((prev: any) => ({ ...prev, isLoading: true }));
    const response = await getIntimationFailureReport(
      init
        ? initialFilter
        : {
            ...filter,
            pageSize: Number(filter.pageSize),
          }
    );
    setSelectedFilter(init ? initialFilter : filter);
    if (response.status === 200) {
      const { content, totalRecord } = response.data;
      const psidObj: IntimationFailureResponse[] = content;
      setIntimations(psidObj);
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj
          ? psidObj.map((p: any, i) => {
              p.srno = (currentPage - 1) * pageSize + i + 1;
              p.isExpired = p.isExpired ? "Yes" : "No";
              return p;
            })
          : [],
        totalPages: 10,
        totalRows: Number(totalRecord),
      }));
    }
  };

  const _initFilters = () => {
    setFilter(initialFilter);

    const serviceDropdown = document.getElementById(
      "serviceDropdown"
    ) as HTMLSelectElement;
    if (serviceDropdown) {
      serviceDropdown.value = "0";
    }
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const serviceChangeHandler = (e: any) => {
    let companyServiceId = "0";
    if (e?.target?.value) {
      companyServiceId = e.target.value;
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, companyServiceId }));
  };

  function onSearchChange(value: any) {
    setFilter((filter: any) => ({
      ...filter,
      consumerNumber: value,
      pageIndex: 0,
    }));
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };
  function exportExcel(): void {
    if (pageData.totalRows > 0) {
      const payload = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        companyServiceId: filter.companyServiceId,
        search: filter.consumerNumber || -1,
        totalItems: pageData.totalRows,
      };

      const userName: any = auth?.username;
      // console.log("Token", userName);
      window.location.assign(
        process.env.REACT_APP_API_URL +
          "/reporting/download/intimationFailureReport/" +
          payload.startDate +
          "/" +
          payload.endDate +
          "/" +
          payload.companyServiceId +
          "/" +
          // payload.companyId +
          // "/" +
          payload.search +
          "/" +
          // payload.psidType +
          // "/" +
          // payload.transactionType +
          // "/" +
          payload.totalItems +
          "/" +
          btoa(userName)
      );
    }
  }

  const COLUMNS = [
    {
      Header: (
        <div className="headerSelectionBox">
          <input
            type="checkbox"
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={selectedRows.length === pageSize}
          />
        </div>
      ),
      accessor: "select",
      Cell: ({ row }: any) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
      ),
    },
    {
      Header: "Consumer Number",
      accessor: "consumerNumber",
    },
    {
      Header: "Challan Number",
      accessor: "challanNumber",
    },
    {
      Header: "Transaction Amount",
      accessor: "transactionAmount",
     Cell: ({ value }: { value: any }) => {
        const cleanedValue = value.replace(/,/g, '');
        const numberValue = parseFloat(cleanedValue);
        if (!isNaN(numberValue)) {
          const integerValue = Math.trunc(numberValue);
          return <strong>{integerValue.toLocaleString()}</strong>;
        }
        return <strong>{value}</strong>; 
      }          
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate",
    },
    {
      Header: "Transaction Time",
      accessor: "transactionTime",
    },
    {
      Header: "Company Service",
      accessor: "companyService",
    },
    {
      Header: "Bank",
      accessor: "bank",
    },
  ];
  const columns: any = COLUMNS;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      getReportData();
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Intimation Failure" });
    getCompanyList();
  }, []);

  console.log("Intimation Failure report generation started.");

  return (
    <div className="dashboard-middle-content">
      {loader || pageData.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <>
        <div className="position-relative">
        <FilterBoxSelectedDate selectedFilter={selectedFilter} isInitimation={true}/>
      </div>
          <button className="resetButton" onClick={resetIntimation}>
            <img
              src={ResetIcon}
              alt="Filter"
              style={{ marginRight: "10px", marginTop: "-2px" }}
            />
            <span>Reset Intimation</span>
          </button>
          <FilterBox
            _initFilters={_initFilters}
            getReportData={getReportData}
            exportExcel={exportExcel}
          >
            <div className="w-100">
              <input
                className="dashboard-top-filter-input mb-2"
                type="date"
                value={filter?.startDate || ""}
                onChange={onChangeStartDate}
                max={today}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input mb-2"
                type="date"
                value={filter?.endDate || ""}
                onChange={onChangeEndDate}
                max={today}
              />
            </div>
            <div className="w-100 form-group has-search">
              <i
                style={{ transform: "rotate(90deg)" }}
                className="fa fa-exchange form-control-feedback"
                aria-hidden="true"
              ></i>
              <SelectSearchDropDown
                setFilter={(companyServiceId: string) =>
                  setFilter((filter: any) => ({ ...filter, companyServiceId }))
                }
                companies={companies}
                setCurrentPage={setCurrentPage}
                selectedValue={filter.companyServiceId}
              />
            </div>
            <div className="w-100">
              <input
                className="form-control"
                type="text"
                value={filter?.consumerNumber || ""}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (value === "" || (/^\d+$/.test(value) && value.length <= 14)) {
                    onSearchChange(value);
                  }
                }}
                placeholder="Consumer Number"
                maxLength={14}
              />
            </div>
          </FilterBox>
          {pageData?.rowData && (
            <>
              <div className="card">
                <AppTable
                  columns={columns}
                  data={pageData.rowData.map((row: any) => ({
                    ...row,
                    consumerNumber: (
                      <div
                        onClick={() => onConsumerNumberClick(row?.id)}
                        style={{
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.textDecoration = "underline")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.textDecoration = "none")
                        }
                      >
                        {row.consumerNumber}
                      </div>
                    ),
                    transactionAmount: numberWithCommas(row.transactionAmount),
                  }))}
                  isLoading={pageData.isLoading || loader}
                />
              </div>
              <Pagination
                totalRows={pageData.totalRows}
                pageChangeHandler={setPageIndex}
                rowsPerPage={filter.pageSize}
                currentPage={currentPage}
                pageItemChangeHandler={onLoadPageItems}
              />
            </>
          )}
        </>
      )}
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-lg"
      >
        <Modal.Header>
          <Modal.Title>Intimations Tries Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container className="d-flex flex-column">
  {intimationTriesDetail?.map((intimation, index) => (
    <Card key={index} className="mb-4 shadow-sm border-0 rounded-lg">
      <Card.Body>
        <Card.Title
          className="text-center mb-4"
          style={{
            color: '#56419f',
            fontSize: '28px',
            fontWeight: 'bold',
          }}
        >
          Intimation Detail {index + 1}
        </Card.Title>
        <Card.Text className="mb-4">
          <p>
            <strong className="mr-2 text-muted">Intimation Time:</strong>
            <span className="text-dark">
              {new Date(intimation.intimationTime).toLocaleString()}
            </span>
          </p>
          <p>
            <strong className="text-muted">Request URL:</strong>{' '}
            <span className="text-dark">{intimation.requestURL}</span>
          </p>
        </Card.Text>

        {intimation.request && (
          <Card className="mb-4 p-3 bg-light border-0 rounded">
            <Card.Title
              className="text-center mb-3"
              style={{ fontSize: '20px', color: '#56419f' }}
            >
              Request Details
            </Card.Title>
            <div className="overflow-y-auto max-h-40">
           {Object.keys(JSON.parse(intimation.request)).map((key) => (
        <div
         key={key}
         className="d-flex justify-content-between"
         style={{ borderBottom: "1px solid #ccc", padding: "5px 0" }}
         >
         <strong className="text-muted">{key}:</strong>
        <span className="text-dark">
        {JSON.parse(intimation.request)[key]}
      </span>
    </div>
  ))}
</div>

          </Card>
        )}

        <Card className="p-3 bg-light border-0 rounded">
          <Card.Title
            className="text-center mb-3"
            style={{ fontSize: '20px', color: '#56419f' }}
          >
            Response
          </Card.Title>
          <pre className="bg-white p-3 rounded shadow-sm overflow-y-auto max-h-40">
            {JSON.stringify(intimation.response, null, 2)}
          </pre>
        </Card>
      </Card.Body>
    </Card>
  ))}
        </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  
}

export default IntimationFailiure;
