import React from 'react'
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import {useState, useEffect} from "react";
import { IPsidData } from '../../models/interfaces/IPsidDetail';
import { Icon } from "react-icons-kit";
import { search } from "react-icons-kit/fa/search";

import {
  getPsidDetail
} from "../../../../services/PsidService";
import Swal from 'sweetalert2';

const PsidDetails = () => {
  const [searchPsid, setSearchPsid] = useState("");
  const [psidResult, setPsidResult] = useState<IPsidData[]>([]);

  const updateHeaderContext = useUpdateHeaderContext();
  const searchPsidDetail = async (init: string) => {
    setPsidResult([]);
    
    const postDate = {
      consumerNumber: init
    };
    try {
        const response = await getPsidDetail(postDate);
        if (response.status === 200) {
          const { content } = response.data
          setPsidResult(content);
        }
    } catch (error: any) {
        if (error.response) {
            // console.error('Status Code:', error.response.status);
            // console.error('Response Data:', error.response.data);
            Swal.fire('Error:', `${error.response.data?.message || 'Something went wrong'}`);
        } else if (error.request) {
            // console.error('No response received:', error.request);
           Swal.fire('Error:', 'Network error: No response from the server');
        } else {
            // console.error('Error setting up request:', error.message);
            Swal.fire('Error:', 'Unexpected error: ' + error.message);
       }
    }
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim(); 
    if (/^\d*$/.test(value)) {
      setSearchPsid(value);
    }
  };

  useEffect(() => {
    updateHeaderContext({ pageTitle: "PSID Details" });
  }, []);
    
  return (
    <div className="dashboard-PsidDetails">
      <div className='row'>
        <div className='col-md-4 '>
          <div className='aboveColoumn'>
            <label className='label heading'>Enter PSID</label>
            <label className='label'>Please Enter PSID to view details</label> 
          </div>
     
        </div>
        <div className="input-container">
              <input className="input" placeholder="Enter PSID" value={searchPsid} onChange={handleChange}/>
              <button  className='SearchButton'  type="button" onClick={(e) => { e.preventDefault();  searchPsidDetail(searchPsid);}}><Icon icon={search} size={17} className="iconSearch" />
              </button>

          </div>

        {psidResult.length > 0 && (
          <div className='col-md-12'>
            <div className='row'>
              {psidResult.map((psidObj, index) => (
                <div className='col-md-4' key={index}>
                  <div className='cardBoxContainer styles_dashboardServiceBox1__54vVN'>
                    <label className='headingdetails'>  PSID Details  {index + 1}</label>
                    <div >
                      <table className='psidtableBg'>
                      <tr className='psidtableBg'>
                            <td className='psidtableBg'>Bill Status</td>
                            <td className='psidtableBg'>
                              <span
                                style={{
                                  color: '#ffffff',
                                  backgroundColor: psidObj?.billStatus === 'UNPAID' ? (psidObj?.expired ? '#fea02e' : '#d55958') : psidObj?.billStatus === 'PAID' ? '#72ca95' : 'transparent',
                                  padding: '5px 12px',
                                  borderRadius: '4px',
                                }}
                              >
                                {psidObj?.expired ? 'EXPIRED' : psidObj?.billStatus}
                              </span>
                            </td>
                          </tr>
                        <tr className='psidtableBg'>
                          <td className='psidtableBg'>Service</td>
                          <td className='psidtableBg'><b>{psidObj?.serviceName}</b></td>
                        </tr>
                        <tr className='psidtableBg'>
                          <td className='psidtableBg'>Consumer Name</td>
                          <td className='psidtableBg'><b>{psidObj?.consumerName}</b></td>
                        </tr>
                        <tr className='psidtableBg'>
                          <td className='psidtableBg'>Consumer Number</td>
                          <td className='psidtableBg'><b>{psidObj?.consumerNumber}</b></td>
                        </tr>
                        <tr className='psidtableBg'>
                          <td className='psidtableBg'>Challan Number</td>
                          <td className='psidtableBg'><b>{psidObj?.challanNumber}</b></td>
                        </tr>
                        {psidObj?.billStatus === 'UNPAID' && (
                          <>
                          <tr className='psidtableBg'>
                              <td className='psidtableBg'>Expiry Date</td>
                              <td className='psidtableBg'><b>{psidObj?.expiryDate}</b></td>
                            </tr>
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Due Date</td>
                              <td className='psidtableBg'><b>{psidObj?.dueDate}</b></td>
                            </tr>
                            {psidObj?.amountAfterDueDate &&
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Amount After Due Date</td>
                              <td className='psidtableBg'><b>{Number(psidObj?.amountAfterDueDate).toLocaleString()} PKR </b></td>
                            </tr>}
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Amount within Due Date</td>
                              <td className='psidtableBg'><b>{Number(psidObj?.amountWithInDueDate).toLocaleString()} PKR </b></td>
                            </tr>
                          </>
                        )}
                        {psidObj?.billStatus !== 'UNPAID' && (
                          <>
                            
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Bank</td>
                              <td className='psidtableBg'><b>{psidObj?.bank}</b></td>
                            </tr>
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Transaction Amount</td>
                              <td className='psidtableBg'><b>{Number(psidObj?.transactionAmount).toLocaleString()} PKR </b></td>
                            </tr>
                            <tr className='psidtableBg'>
                              <td className='psidtableBg'>Transaction Date</td>
                              <td className='psidtableBg'><b>{psidObj?.transactionDate}</b></td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className='psidtableBg'>Confirmation</td>
                          <td className='psidtableBg'>
                            <span
                              style={{
                                color: '#000000',
                                backgroundColor: psidObj?.confirmed ? '#deefe5' : '#ffebd9',
                                padding: '5px 12px',
                                borderRadius: '4px'
                              }}
                            >
                             <b>{psidObj?.confirmed ? 'Confirmed' : 'Not Confirmed'}</b>
                            </span>
                          </td>
                        </tr>
                        <tr className='psidtableBg'>
                          <td className='psidtableBg'>Intimation</td>
                          <td className='psidtableBg'>
                            <span
                              style={{
                                color: '#000000',
                                backgroundColor: psidObj?.confirmed ? '#deefe5' : '#ffebd9',
                                padding: '5px 12px',
                                borderRadius: '4px'
                              }}
                            >
                             <b>{psidObj?.intimatedToDepartment ? 'Intimated' : 'Not Intimated'}</b> 
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsidDetails;
