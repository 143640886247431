import React, { useState, useEffect } from "react";
import { DashboardFilter } from "../models/DashboardFilter";
import moment from "moment";
import { dt } from "../../../helpers/AppHelpers";
import { getDashboardStats } from "../../../services/PsidService";
import { useUpdateHeaderContext } from "../../../contexts/HeaderContext";
import { useAuth } from "../../auth/core/Auth";
import DashboardBox from "../reusableComponents/DashboardBox";
import Dashboard1 from "../../../../assets/images/dashboard1.png";
import Dashboard2 from "../../../../assets/images/dashboard2.png";
import Dashboard3 from "../../../../assets/images/dashboard3.png";
import DashboardServiceBox from "../reusableComponents/DashboardServiceBox";
import DashboardCompanyBox from "../reusableComponents/DashboardCompanyBox";

import FilterBox from "../reusableComponents/FilterBox";

import FilterBoxSelectedDate from "../reusableComponents/FilterBoxSelectedDate";

export default function Dashbord() {
  const { auth } = useAuth();
  const updateHeaderContext = useUpdateHeaderContext();
  const today = new Date().toISOString().split("T")[0];
  const _newDate = moment(new Date()).format(dt.dbDateFormat);
  const [filter, setFilter] = useState<any>({
    startDate: _newDate,
    endDate: _newDate,
  });
  const [selectedFilter, setSelectedFilter] = useState<any>({
    startDate: _newDate,
    endDate: _newDate,
  });
  const [loading, setLoading] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState<Number>(0);
  const [totalRevenue, setTotalRevenue] = useState<Number>(0);
  const [pitbCharges, setPitbCharges] = useState<Number>(0);
  const [companyStats, setCompanyStats] = useState([]);
  const [stats, setStats] = useState([]);
  const [ltd, setLtd] = useState(false);

  const _initFilters = () => {
    setFilter({
      startDate: _newDate,
      endDate: _newDate,
    });
    getStats(true);
  };

  const onChangeStartDate = (e: any) => {
    setFilter((filter: any) => ({
      ...filter,
      startDate: e.target.value,
    }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((filter: any) => ({
      ...filter,
      endDate: e.target.value,
    }));
  };

  const getStats = async (init: boolean = false) => {
    setLoading(true);
    const payload: DashboardFilter = {
      startDate: filter.startDate,
      endDate: filter.endDate,
    };
    const initPayload = {
      startDate: _newDate,
      endDate: _newDate,
    };
    setSelectedFilter(init ? initPayload : payload);
    const response = await getDashboardStats(init ? initPayload : payload);
    if (response.status === 200) {
      const stats = response.data.content?.length ? response.data.content : [];
      setStats(stats);
    }
    setLoading(false);
    setLtd(false);
  };

  const onLTDClick = () => {
    setFilter((filter: any) => ({
      ...filter,
      startDate: '2021-11-01',
    }));
    setLtd(true);
  };

  const prepareStats = () => {
    let totalTransactions = 0;
    let totalRevenue = 0;
    let pitbCharges = 0;
    if (stats.length > 0) {
      stats.forEach((s: any) => {
        totalTransactions += Number(s.transactionCount);
        totalRevenue += parseFloat(s.transactionAmount);
        pitbCharges += parseFloat(s.pitbCharges);
      });
    }
    setTotalTransactions(totalTransactions);
    setTotalRevenue(totalRevenue);
    setPitbCharges(pitbCharges)
    const companyGroupedData = stats.reduce((acc: any, transaction: any) => {
      const existingCompany = acc.find(
        (item: any) => item.companyName === transaction.companyName
      );

      if (existingCompany) {
        existingCompany.transactionCount += Number(transaction.transactionCount);
        existingCompany.transactionAmount += parseFloat(transaction.transactionAmount);
      } else {
        acc.push({
          companyId :transaction.companyId, 
          companyName: transaction.companyName,
          transactionCount: Number(transaction.transactionCount),
          transactionAmount: parseFloat(transaction.transactionAmount),
        });
      }
      return acc;
    }, []);
    setCompanyStats(companyGroupedData);
  };

  const adminRole = () => {
    return ["ROLE_ADMIN", "ROLE_PITB_ADMIN"].includes(auth?.role.roleType || "")
  }

  useEffect(() => {
    getStats();
    updateHeaderContext({ pageTitle: "Dashboard" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    prepareStats();
  }, [stats]);

  useEffect(() => {
    if (ltd) getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltd, filter]);

  return (
    <div className="dashboard-middle-content">
      <div className="position-relative">
        <FilterBoxSelectedDate selectedFilter={selectedFilter} isDashboard={true}/>
      </div>
      {adminRole() && !loading && (
        <div className="position-relative">
          <button type="button" className="ltdtButton heightBtn" onClick={onLTDClick} title="Launch till date">LTD</button>
        </div>
      )}
      {!loading && (
        <FilterBox getReportData={getStats} _initFilters={_initFilters}>
          <div className="w-100 mb-3">
            <input
              className="dashboard-top-filter-input"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
              max={today}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.currentTarget.focus();
              }}
            />
          </div>
          <div className="w-100">
            <input
              className="dashboard-top-filter-input"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
              max={today}
            />
          </div>
        </FilterBox>
      )}
      {loading ? (
        <div className="row">
          {[1, 2, 3].map((_, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="dashboard-box  "></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="row">
          <div className="col-md-4 mb-4 marginmobile">
            <DashboardBox  
              title={"Number of Transaction"}
              value={totalTransactions.toLocaleString()}
              iconPath={Dashboard1}
            />
          </div>
          <div className="col-md-4 mb-4">
            <DashboardBox
              title={"Revenue Collected"}
              value={'PKR ' + totalRevenue.toLocaleString()}
              iconPath={Dashboard2}
              backgroundColor={`#e9f8f2`}
            />
          </div>
          {adminRole() && (
            <div className="col-md-4 mb-4">
              <DashboardBox
                title={"PITB Collection"}
                value={'PKR ' + pitbCharges.toLocaleString()}
                iconPath={Dashboard3}
                backgroundColor={`#FEF1E2`}
              />
            </div>
          )}
  
          {adminRole() ? (
            <div className="col-md-12">
              <div className="tabbable-responsive">
                <div className="tabbable">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Company</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ paddingLeft: '0' }} className="card-body">
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div className="row">
                      {companyStats.map((s: any, index) => (
                     <div className="col-md-4 mb-4" key={index}>
                          <DashboardCompanyBox
                            key={index}
                            title={s.companyName}
                            companyId={s.companyId}
                            amountCollected={'PKR ' + Number(s.transactionAmount).toLocaleString()}
                            nOfTransactions={Number(s.transactionCount).toLocaleString()}
                          />
                     
                   </div> ))}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                    <div className="row">
                      {stats.map((s: any, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          <DashboardServiceBox
                            key={index}
                            title={s.serviceName}
                            companyId={s.companyId}
                            amountCollected={'PKR ' + Number(s.transactionAmount).toLocaleString()}
                            nOfTransactions={Number(s.transactionCount).toLocaleString()}
                            />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <div className="row">
              {stats.map((s: any, index)=> (
                  <div className="col-md-4 mb-4" key={index}>
                    <DashboardCompanyBox
                      title={s.serviceName}
                      companyId={s.companyId}
                      amountCollected={'PKR ' + Number(s.transactionAmount).toLocaleString()}
                      nOfTransactions={Number(s.transactionCount).toLocaleString()}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

