import { useMemo, useState, useEffect } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import {
  ILinkConfirmation,
  LinkConformationResponse,
} from "../../models/interfaces/ILinkConfirmation";
import { getLinkConfirmationDetails } from "../../../../services/LinkConfirmation";

import { Card } from "react-bootstrap";

function LinkConfirmation() {
  const updateHeaderContext = useUpdateHeaderContext();
  const [filter, setFilter] = useState<ILinkConfirmation>({
    consumerNumber: "",
    billType: "INVOICE",
  });

  const [linkDetails, setLinkDetails] = useState<LinkConformationResponse>();
  const billType: any = useMemo(() => ["INVOICE", "TOPUP"], []);

  const statusChangeHandler = (e: any) => {
    const billType = e.target.value;
    setFilter((filter: any) => ({ ...filter, billType }));
  };

  const getReportData = async () => {
    const response = await getLinkConfirmationDetails(filter);

    if (response.status === 200) {
      setLinkDetails(response.data.content?.[0]);
    }
  };

  const onChangeConsumerNumber = (e: any) => {
    const value = e.target.value.trim();
    if (value === "" || (/^\d+$/.test(value) && value.length <= 14)) {
      setFilter((prev) => ({ ...prev, consumerNumber: value }));
    }
  };

  useEffect(() => {
    updateHeaderContext({ pageTitle: "1Link Confirmation" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      {/* <FilterBox getReportData={getReportData}></FilterBox> */}
      <Card className="mb-4">
        <div className="row p-4">
          <div className="w-10">
            <select className="form-control" onChange={statusChangeHandler}>
              {billType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <input
              className="form-control"
              type="text"
              value={filter?.consumerNumber || ""}
              onChange={onChangeConsumerNumber}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>
          <div className="col-md-2">
            <button
              className="filterButton"
              onClick={getReportData}
              style={{ width: "100%" }}
            >
              Search
            </button>
          </div>
        </div>
      </Card>
      {linkDetails && Object.keys(linkDetails).length && (
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">1Link Confirmation details</div>
            <div className="card-body">
              {linkDetails &&
                Object.keys(linkDetails).map((key: string) => (
                  <div style={{ display: "flex" }}>
                    <p key={key} style={{ width: "53.5%" }}>
                      <strong>{key.replaceAll("_", " ")}</strong>
                    </p>
                    <p>{linkDetails[key as keyof typeof linkDetails]}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default LinkConfirmation;
