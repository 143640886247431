import React, { useState } from "react";
import { useField } from "formik";
import { InputGroup, FormControl } from "react-bootstrap";
import classes from "./styles.module.css";

interface PasswordInputProps {
  name: string;
  className?: string;
  [x: string]: any;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  className,
  ...props
}) => {
  const [field, meta] = useField(name);
  const [show, setShow] = useState(false);

  const isInvalid = meta.error && meta.touched;

  return (
    <InputGroup className={classes.passwordInputgroup} style={{ position: "relative" }}>
      <FormControl
        {...field}
        {...props}
        type={show ? "text" : "password"}
        className={`${className} ${isInvalid ? "is-invalid" : ""}`}
      
      />
      <div
        className={
          isInvalid ? classes.togglePasswordInvalid : classes.togglePassword
        }
        onClick={() => setShow(!show)}
        style={{
          zIndex: 4, 
        }}
      >
        <i className={show ? "bi bi-eye-slash" : "bi bi-eye"}></i>
      </div>
      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </InputGroup>
  );
};

export default PasswordInput;
