import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

const Pagination = ({
  pageChangeHandler,
  totalRows,
  rowsPerPage,
  currentPage,
  pageItemChangeHandler,
}: any) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  // const pagesArr = [...new Array(noOfPages)];

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  // const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // These variables give the first and last record/row number
  // with respect to the current page
  const [pageFirstRecord, setPageFirstRecord] = useState(1);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  // Onclick handlers for the butons
  const onNextPage = () => pageChangeHandler(currentPage + 1);
  const onPrevPage = () => pageChangeHandler(currentPage - 1);
  const onPageSelect = (pageNo: number) => pageChangeHandler(pageNo);
  const pageItemsChange = (event: any) => {
    pageItemChangeHandler(event.target.value);
  };

  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(startPage + 4, noOfPages);
  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    // pageChangeHandler(skipFactor);
    // pageChangeHandler(currentPage)
    setPageFirstRecord(skipFactor + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <>
      {noOfPages > 0 ? (
        <div className={styles.pagination}>
          <div className={styles.pageInfo}>
            <select
              id="pageItems"
              onChange={pageItemsChange}
              className={styles.hreSelect}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp; Showing {pageFirstRecord} -{" "}
            {totalRows < currentPage * rowsPerPage
              ? totalRows
              : currentPage * rowsPerPage}{" "}
            of {totalRows}
          </div>
          <div className={styles.pagebuttons}>
            <button
              className={styles.pageBtn}
              onClick={onPrevPage}
              disabled={!canGoBack}
            >
              {"<"}
            </button>
            {Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            ).map((page) => (
              <button
                key={page}
                onClick={() => onPageSelect(page)}
                className={`${styles.pageBtn} ${
                  page === currentPage ? styles.activeBtn : ""
                }`}
              >
                {page}
              </button>
            ))}
            <button
              className={styles.pageBtn}
              onClick={onNextPage}
              disabled={!canGoNext}
            >
              {">"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
