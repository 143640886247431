import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { useEffect, useState, useMemo, useCallback } from "react";
import username from '../../../../assets/images/username_user.png'
import link_permission from '../../../../assets/images/link_permission.png'
import location_permission from '../../../../assets/images/location_permission.png'
import parent_permission from '../../../../assets/images/parent_permission.png'
import plus from "../../../../../src/assets/images/Vector.png" 
import * as Yup from "yup";
import {
  getInterfaces,
  updateInterfacesStatus,
  updateInterfaces,
  saveInterfaces,
} from "./../../../services/UserService";
import { useUpdateHeaderContext } from "../../../contexts/HeaderContext";
import { Modal } from "react-bootstrap";
import classes from "./user-management/styles.module.css";
import { Dropdown } from "react-bootstrap";

let styleObj = {
  fontSize: 14,
  color: "#4a54f1",
  paddingTop: "100px",
};

const submitScheme = Yup.object().shape({
  interfaceName: Yup.string()
    .min(3, "Minimum 3 character")
    .max(20, "Maximum 20 character")
    .required("Name is required"),
  interfaceLink: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Link is required"),
  menuLocation: Yup.string(),
  parentLink: Yup.string(),
});

const initialValues = {
  interfaceName: "",
  interfaceLink: "",
  menuLocation: "",
  parentLink: "",
};

function UserInterfaces() {
  const updateHeaderContext = useUpdateHeaderContext();
  const menuLocations: any = useMemo(
    () => ["OTHER", "SIDEBAR", "TOPBAR", "MAINMENU"],
    []
  );
  const [interfaces, setInterfaces] = useState<any>([]);
  const [menuLocationSelect] = useState("");
  const [formValues, setFormValues] = useState<any>(initialValues);
  const [updateUserTitle, setUpdateUserTitle] = useState(false);
  const [isSending, setIsSending] = useState(false);
  useCallback(
    async (id: any, status: any) => {
      // don't send again while we are sending
      if (isSending) return;
      const postdata = {
        id: id,
        active: status,
      };
      // update state
      setIsSending(true);
      // send the actual request
      await updateInterfacesStatus(postdata);
      // once the request is sent, update state again
      setIsSending(false);
    },
    [isSending]
  ); // update the callback if the state changes

  const [loading, setLoading] = useState(false);
  const [isPermissionUpdate, setPermissionUpdate] = useState(false);
  const [permissionId, setPermissionID] = useState(0);
  const [interfaceForm, setInterfaceForm] = useState(false);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var perId = 0;
      try {
        if (isPermissionUpdate) {
          perId = permissionId;
        }
        const postdata = {
          id: perId,
          interfaceType: values.interfaceName,
          interfaceLink: values.interfaceLink,
          parentInterface: "",
          menuLocation: menuLocationSelect,
        };
        if (isPermissionUpdate) {
          await updateInterfaces(postdata);
        } else {
          await saveInterfaces(postdata);
        }
        setSubmitting(false);
        setLoading(false);
        getInterfacesList();
        values.interfaceName = "";
        values.interfaceLink = "";
        setPermissionUpdate(false);
        setPermissionID(0);
      } catch (error) {
        console.error(error);
        // saveAuth(undefined)
        setStatus("The fail to submit");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const updateValues = (interfaceObj: any) => {
    setInterfaceForm(true);
    setFormValues({
      interfaceName: interfaceObj.interfaceType,
      interfaceLink: interfaceObj.interfaceLink,
      menuLocation: interfaceObj.menuLocation,
      parentLink: interfaceObj.parentInterface
        ? interfaceObj.parentInterface
        : "",
    });
    setPermissionUpdate(true);
    setPermissionID(interfaceObj.id);
    setUpdateUserTitle(true);
  };

  const getInterfacesList = async () => {
    const response = await getInterfaces();
    setInterfaces(response.data.content);
  };
  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status,
    };
    await updateInterfacesStatus(postData);
    getInterfacesList();
  };

  // const meunLocationChangeHandler = (e: any) => {
  //   let menuText = "";
  //   if (e.target.value) {
  //     menuText = e.target.value;
  //   }

  //   setMenuLocationSelect(menuText);
  // };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    var perId = 0;
    try {
      if (isPermissionUpdate) {
        perId = permissionId;
      }
      const postdata = {
        id: perId,
        interfaceType: data.interfaceName,
        interfaceLink: data.interfaceLink,
        parentInterface: data.parentLink,
        menuLocation: data.menuLocation,
      };

      if (isPermissionUpdate) {
        await updateInterfaces(postdata);
      } else {
        await saveInterfaces(postdata);
      }
      setLoading(false);
      getInterfacesList();
      setPermissionUpdate(false);
      setPermissionID(0);
      cancelRequest();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const cancelRequest = () => {
    setFormValues(initialValues);
    setInterfaceForm(false);
    setUpdateUserTitle(false);
  };

  const showInterfaceForm = () => {
    setInterfaceForm(true);
    setUpdateUserTitle(false);
  };

  var rows =
    interfaces &&
    interfaces.length > 0 &&
    interfaces.map((row: any, index: any) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          {/* <td>{row.id}</td> */}
          <td>{row.interfaceType}</td>
          <td>{row.interfaceLink}</td>
          <td>{row.parentInterface}</td>
          <td>{row.menuLocation}</td>
          <td className="">
            {/* <button
              className={
                row.active ? "btn btn-active" : "btn btn-inactive"
              }
              onClick={async () => {
                await updateStatus(row.id, !row.active);
              }}
            >
              {row.active ? "Active" : "Inactive"}
            </button> */}
                 <span
      style={{
        fontWeight: 'bold',
        color: row.active ? '#48C499' : 'black',
      }}
    >
      {row.active ? "ACTIVE" : "INACTIVE"}
    </span>
          </td>
          <td className="">
            {/* <button
              className="btn btn-edit"
              onClick={() => updateValues(row)}
            >
              Edit
            </button> */}
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                size="sm"
                className={classes.dropdownToggleAfter}
              >
                <i
                  className="bi bi-three-dots-vertical icon-red"
                  style={{ color: "green" }}
                ></i>
              </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateValues(row)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await updateStatus(row.id, !row.active);
                }}
              >
                {row.active ? "Active" : "Inactive"}
              </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
          </td>
        </tr>
      );
    });

  useEffect(() => {
    updateHeaderContext({ pageTitle: "User Interfaces" });

    getInterfacesList();
  }, []);

  return (
    <div className="container-fluid" style={styleObj}>
      <div className="col-md-12">
        <div className="white-bg">
          <div className="float-right">
            <button
              className={`mb-3 ${classes.addRoleButton}`}
              onClick={showInterfaceForm}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
          src={plus}
          alt="Add User"
          style={{
            marginRight:'9px',
            width:'10%',
            height:'auto',
            marginTop:'-2px'
          }}
        />
              <span>Add Interfaces</span>
            </button>
          </div>

          {interfaceForm && (
            <Modal
              size="lg"
              style={{ width: "800px", margin: "auto", marginTop: "100px" }}
              show={interfaceForm}
              onHide={() => setInterfaceForm(false)}
            >
              <div className="modal-content p-2">
                <div style={{ backgroundColor: "white", padding: "30px" }}>
                <h3 className="text-center mb-5" style={{ fontWeight:'600' }}>{updateUserTitle ? "Edit Interface": "Add Interface"}</h3>
                  <Formik
                    initialValues={formValues}
                    validationSchema={submitScheme}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({ errors, touched, resetForm }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                           <img src={username} alt=""style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}} />
                            <Field
                             style={{
                              padding:"1.375rem 2.4rem"
                            }}
                              className={
                                "form-control w-100" +
                                (errors.interfaceName && touched.interfaceName
                                  ? " is-invalid"
                                  : "")
                              }
                              type="text"
                              id="interfaceName"
                              name="interfaceName"
                              autoComplete="off"
                              placeholder="Display Name"
                            />
                            <ErrorMessage
                              name="interfaceName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                           <img src={link_permission} alt=""style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}} />
                            <Field
                              style={{
                                padding:"1.375rem 2.4rem"
                              }}
                              className={
                                "form-control w-100" +
                                (errors.interfaceLink && touched.interfaceLink
                                  ? " is-invalid"
                                  : "")
                              }
                              type="text"
                              id="interfaceLink"
                              name="interfaceLink"
                              autoComplete="off"
                              placeholder="Link"
                            />
                            <ErrorMessage
                              name="interfaceLink"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                          <img src={location_permission} alt=""style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}} />
                            <Field
                             style={{
                             padding: '0.375rem 2.4rem',
                          height: 'calc(3.25rem + -7px)'
                            }}
                              as="select"
                              name="menuLocation"
                              className="form-control"
                            >
                              <option value="">Menu Location</option>
                              {menuLocations.map((menuLocation: any) => (
                                <option key={menuLocation} value={menuLocation}>
                                  {menuLocation}
                                </option>
                              ))}
                            </Field>

                            {/* <select className='form-control' id="menuLocation" name="menuLocation" onChange={meunLocationChangeHandler}>
                        <option value="">Select Menu Location</option>
                        {
                          menuLocations.map((menuLocation: any) => (<option key={menuLocation} value={menuLocation}>{menuLocation}</option>))
                        }
                      </select> */}
                          </div>
                          <div className="col-md-6 mb-3">
                          <img src={parent_permission} alt="" style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}}/>
                            <Field
                              style={{
                             padding: '0.375rem 2.4rem',
                          height: 'calc(3.25rem + -7px)'
                              }}
                              as="select"
                              name="parentLink"
                              className="form-control"
                            >
                              <option value="">Parent Menu</option>
                              {interfaces &&
                                interfaces.length > 0 &&
                                interfaces.map(
                                  (row: any, index: any) =>
                                    row.interfaceLink === "#" && (
                                      <option
                                        key={row.id}
                                        value={row.interfaceType}
                                      >
                                        {row.interfaceType}
                                      </option>
                                    )
                                )}
                            </Field>
                            {/* <Field
                        className={
                          'form-control w-100' +
                          (errors.parentLink && touched.parentLink ? ' is-invalid' : '')
                        }
                        type="text"
                        id="parentLink"
                        name="parentLink"
                        autoComplete='off' />
                      <ErrorMessage
                        name="parentLink"
                        component="div"
                        className="invalid-feedback"
                      /> */}
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-md-6"></div>
                          <div className="col-md-6">
                            <div className="float-right mt-4">
                              <button
                                className={`${classes.cancelButton} mr-4`}
                                type="button"
                                onClick={cancelRequest}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                id="sign_in_submit"
                                className={classes.saveButton}
                              >
                                {!loading && (
                                  <span className="indicator-label">Save</span>
                                )}
                                {loading && (
                                  <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                  >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="login_form"
                  >
                    {formik.status ? (
                      <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">
                          {formik.status}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      {interfaces && interfaces.length>0 && (
        <div
          className="mt-5"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          style={{ margin: "20px" }}
        >
          <table>
            <thead>
              <tr>
                <th>Sr#</th>
                {/* <th>Id</th> */}
                <th>Name</th>
                <th>Link</th>
                <th>Parent Menu</th>
                <th>Menu Location</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UserInterfaces;
